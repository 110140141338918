import React from "react"
import {
  BoxProps,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Input,
  InputProps,
  InputGroup,
  InputRightElement,
  IconButton,
  Box,
} from "@chakra-ui/core"
import DatePicker, { ReactDatePickerProps } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { commonStyles } from "./utils"
import { ClassNames } from "@emotion/core"
import format from "date-fns/format"
import { useFormContext } from "react-hook-form"
import styled from "@emotion/styled"

type DateFieldProps = Omit<
  BoxProps,
  "onChange" | "defaultValue" | "children"
> & {
  onChange?: (date: Date) => void
  isInvalid?: boolean
  isRequired?: boolean
  name?: string
  placeholder?: string
  defaultValue?: string
  dateFormat?: string
  datePickerProps?: Partial<ReactDatePickerProps>
}

const StyledInput = styled(Input)`
  background-color: white !important;
`

const DateField = React.forwardRef(function DateField(
  {
    isInvalid,
    isRequired,
    onChange,
    name,
    placeholder,
    defaultValue,
    dateFormat = "P",
    datePickerProps = {},
    ...props
  }: DateFieldProps,
  ref: InputProps["ref"]
) {
  const [date, setDate] = React.useState<Date | null>(
    defaultValue ? new Date(defaultValue) : null
  )
  return (
    <Popover usePortal>
      <PopoverTrigger>
        <Box {...props}>
          <InputGroup>
            <StyledInput
              isInvalid={isInvalid}
              isRequired={isRequired}
              {...commonStyles(isInvalid)}
              name={name}
              placeholder={placeholder}
              value={date ? format(date, dateFormat) : ""}
              isReadOnly
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === "Backspace" || e.key === "Delete") {
                  setDate(null)
                }
              }}
              ref={ref}
            />
            {date && (
              <InputRightElement>
                <IconButton
                  size="xs"
                  aria-label="Reset date"
                  icon="close"
                  onClick={() => {
                    setDate(null)
                  }}
                />
              </InputRightElement>
            )}
          </InputGroup>
        </Box>
      </PopoverTrigger>
      <PopoverContent
        border="0"
        width="auto"
        height="auto"
        borderRadius="0.5rem"
        backgroundColor="transparent"
        zIndex={4}
      >
        <ClassNames>
          {({ css }) => (
            <DatePicker
              selected={date}
              calendarClassName={css({
                width: "100%",
                height: "100%",
                border: 0,
                ".react-datepicker__month-container": {
                  width: "100%",
                  height: "100%",
                },
              })}
              onChange={(date: Date) => {
                onChange?.(date)
                setDate(date)
              }}
              inline
              {...datePickerProps}
            />
          )}
        </ClassNames>
      </PopoverContent>
    </Popover>
  )
})

export default DateField

export function ConnectedDateField({ isRequired, ...props }: DateFieldProps) {
  const { register, errors } = useFormContext()
  return (
    <DateField
      {...props}
      ref={register({ required: isRequired })}
      isInvalid={!!errors[props.name || ""]}
    />
  )
}
