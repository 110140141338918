/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import config from "../../config"

const url = process.env.URL || ""

type SEOProps = {
  title: string
  description?: string
  lang?: string
  meta?: {}[]
  json?: Record<string, any>[]
  image?: string
  isBlogPost?: boolean
  location?: Location
}

function SEO({
  description,
  lang,
  meta = [],
  title,
  json,
  image,
  location,
  isBlogPost = false,
}: SEOProps) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaImage =
    image || `${url || site.siteMetadata.siteUrl}/images/sixthreezero_Logo.png`

  const metaData = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: isBlogPost ? "article" : "website",
    },
    {
      name: "og:image",
      content: metaImage,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: "twitter:image",
      content: metaImage,
    },
    {
      name: "fb:app_id",
      content: config.GATSBY_FACEBOOK_APP_ID,
    },
    ...meta,
  ]

  if (location) {
    metaData.push({
      name: "og:url",
      content: `${location.origin}${location.pathname}`,
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={metaData}
    >
      {json && (
        <script type="application/ld+json">{`${JSON.stringify(json)}`}</script>
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
